.GHWrapper {
  grid-area: gh;
  margin: auto;
  display: relative;
}
.GHImg{
  aspect-ratio: 1/1;
  height: auto;
}
.GHLink{
  aspect-ratio: 1/1;
  width: 15vmin;;
}

.GHLogo{
  animation: GHLogoPulse infinite 2s linear;
  margin-bottom: 2vmin;
}
@keyframes GHLogoPulse {
  from {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  to{
    transform: scale(1);
  }
}

.GHLogo:hover{
  cursor: pointer;
}