.meOuter{
  grid-area: pic;
  place-self: center;
  align-items: center;
  padding-left: 5vmin;
  padding-right: 5vmin;
}
.me{
  border-radius: 15%;
  aspect-ratio: auto;
  width: auto;
  max-height: 75vmin;
  grid-area: pic;
}
.me:hover{
  transform: scale(1.01);
  transition: transform 0.2s;
}
.me:not(hover){
  transform: scale(1);
  transition: transform 0.2s;
}