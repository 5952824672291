@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&display=swap');

.blurb {
  grid-area: blurb;
  margin: 5vmin;
  min-width: 35vmin;
  place-self: center;
}

.blurbText {
  font-family: 'Roboto Condensed', sans-serif;
  margin-top: 0px;
  margin-bottom: 0px;
  /* text-wrap: nowrap; */
}
.blurbText:hover {
  transform: scale(1.01);
  transition: transform 0.2s;
}
.blurbText:not(hover) {
  transform: scale(1);
  transition: transform 0.2s;
}

.greeting {
  font-size: 6vmin;;
  text-align: center;
}

.nameIntro {
  text-align: left;
  font-size: 4vmin;
}

.name {
  text-align: left;
  font-size: 7vmin;
}

.makeStuff {
  text-align: right;
  font-size: 4vmin;
}