/* #root{
  display: grid;
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
  align-items: center;
  align-content: center;
} */

.app{
  display: grid;
  margin: auto;
  grid-template-columns: 50vmin 50vmin;
  grid-template-areas:  "pic blurb"
                        "pic gh";
  height: 75vmin;
  width: 75vmin;
  column-gap: 20vmin;
  align-items: center;
  justify-items: right;
  place-content: center;
}

@media (max-width: 100vmin){
  .app{
    grid-template-columns: 1fr;
    grid-template-areas:  "pic"
                          "blurb"
                          "gh";
    height: 100vmin;
    width: 100vmin;
  }
}