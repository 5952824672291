.bg-wrapper {
  margin: 0;
  width: 100vw;
  height: 100vh;
  align-content: center;
  position: fixed;
  top: 0;
  left: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(-45deg, #f56e30, #bb56d4, #37b2df, #a9ce55);
	background-size: 350vmax 350vmax;
	animation: gradient 45s linear infinite;
}

@keyframes gradient {
	0% {
		background-position: 0% 0%;
	}
	20% {
		background-position: 0% 100%;
	}
  40% {
		background-position: 100% 100%;
	}
	60% {
		background-position: 0% 100%;
	}
  80% {
    background-position: 50% 50%;
  }
  100% {
    background-position: 0% 0%;
  }
}